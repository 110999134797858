/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 17/12/2024
 */

import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

export const HrAppEmployee = [
    {
        path: appHelper.path.hrApp + '/employee',
        name:'hrAppEmployee',
        component: () => import('../views/employee/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.hrApp + '/employee/blood',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'hr-blood',
                path: '',
                component: () => import('../views/blood/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    },
    {
        path: appHelper.path.hrApp + '/employee/religion',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'hr-religion',
                path: '',
                component: () => import('../views/blood/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    },
    {
        path: appHelper.path.hrApp + '/employee/marital',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'hr-marital',
                path: '',
                component: () => import('../views/blood/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    },
    {
        path: appHelper.path.hrApp + '/employee/education',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'hr-education',
                path: '',
                component: () => import('../views/blood/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    },
    {
        path: appHelper.path.hrApp + '/employee/family',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'hr-family',
                path: '',
                component: () => import('../views/blood/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    },
    {
        path: appHelper.path.hrApp + '/employee/status',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'hr-status',
                path: '',
                component: () => import('../views/status/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    },
    {
        path: appHelper.path.hrApp + '/employee/grade',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'hr-grade',
                path: '',
                component: () => import('../views/grade/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    }
];
