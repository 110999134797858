<template>
    <draggable
        tag="ul"
        :list="items"
        :group="{ name: 'g1' }"
        ghost-class="ghost"
        item-key="id"
        :disabled="true"
    >
        <template #item="{ element, index }">
            <li :class="getClass(element)">
                <span v-if="element.menu_type==='devider'" class="menu-header-text" :data-i18n="element.name">{{ element.name }}</span>
                <a
                    v-if="element.menu_type!=='devider' && element.menu_type!=='tab' && (!element.route_frontend || element.menus.length)"
                    href="javascript:void(0)"
                    class="menu-link"
                    :class="[element.menus.length ? 'menu-toggle' : '']"
                >
                    <i
                        class="menu-icon tf-icons"
                        :class="[(element.icon_type==='tabler'?'ti ti-':'bi bi-') + (element.icon_class?element.icon_class:'apps')]"
                    ></i>
                    <div :data-i18n="element.name">{{ element.name }}</div>
                </a>
                <router-link
                    v-if="element.menu_type!=='devider' && !(element.menu_type!=='tab' && (!element.route_frontend || element.menus.length))"
                    :to="getHomePath(element) + element.route_frontend"
                    class="menu-link"
                >
                    <i
                        class="menu-icon tf-icons"
                        :class="[(element.icon_type==='tabler'?'ti ti-':'bi bi-') + (element.icon_class?element.icon_class:'apps')]"
                    ></i>
                    <div :data-i18n="element.name">{{ element.name }}</div>
                </router-link>
                <MainMenuItem
                    class="menu-sub"
                    v-if="element.menu_type!=='devider' && element.menu_type!=='tab' && element.menu_type!=='module' && element.menus.length"
                    :items="element.menus"
                />
            </li>
        </template>
    </draggable>
</template>

<script>
import draggable from "vuedraggable";
import {appHelper} from "../../utils/helpers/appHelper.js";

export default {
    name: "MainMenuItem",
    components : {
        draggable
    },
    props: {
        items: {
            type: Array,
            default : []
        }
    },
    methods: {
        getClass(item) {
            let classString = '';
            if(item.menu_type==='devider') {
                return 'menu-header small text-uppercase';
            } else if((item.menu_type==='tab' || item.menu_type==='module')) {
                if((item.focus||null)) {
                    classString = 'active';
                }
            } else if((item.focus||null)) {
                classString = 'active';
                if(item.menus.length) {
                    classString = 'open active';
                }
            }

            return 'menu-item' + (classString ? (' ' + classString) : '');
        },
        getHomePath(element) {
            if(element.route_type) {
                return '';
            }

            return appHelper.path.home;
        }
    }
}
</script>

<style scoped>

</style>
