import auth from '../../../middleware/auth.js';
import {appHelper} from "../../../utils/helpers/appHelper.js";
import shortcuts from "../../../middleware/shortcuts.js";
export const AdminUsers = [
    {
        path: appHelper.path.adminApp + '/authenticate',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'ubac',
                path: '',
                component: () => import('../views/users/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'abac',
                path: 'abac',
                component: () => import('../views/abac/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'rbac',
                path: 'rbac',
                component: () => import('../views/roles/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'recent-device',
                path: 'recent-device',
                component: () => import('../views/recent-device/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'device-identity',
                path: 'device-identity',
                component: () => import('../views/device-identity/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    }
];
