import {appHelper} from "../utils/helpers/appHelper.js";
import router from "../routers/router.js";
import {elementHelper} from "../utils/helpers/elementHelper.js";
export default async function auth({to, next, store}) {
    let response = store.getters["auth/authUser"];
    const loginQuery = {path: appHelper.path.login, query: {redirect: to.fullPath}};
    const subscribeApp = function () {
        const appKey = appHelper.subscriberApp.appKey();
        if(to.name === "home" && appHelper.subscriberApp.isAppSlug() && appKey==='app') {
            if(response.is_subscriber && !(response.subscriptions || []).length) {
                store.dispatch("auth/setGuest", {value: "isGuest"});
                router.push(loginQuery);
            } else if(response.is_subscriber && (response.subscriptions || []).length) {
                window.location.href = appHelper.url.root() + '/' + response.subscriptions[0].id;
            } else {
                next();
            }
        } else {
            const pathArray = to.fullPath.split('/').filter(value => value !== "");
            if(pathArray.length) {
                if (appHelper.subscriberApp.listSpaApp().includes(pathArray[0]) && pathArray[0]!=='adminapp') {
                    store.dispatch("auth/setGuest", {value: "isGuest"});
                    router.push(loginQuery);
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    }

    if (!response) {
        elementHelper.loader.show('body_loader', window.document.body);
        await store.dispatch("auth/getAuthUser").then(() => {
            response = store.getters["auth/authUser"];
            if (!response) {
                store.dispatch("auth/setGuest", {value: "isGuest"});
                router.push(loginQuery);
            } else if(!appHelper.subscriberApp.isAppSlug()) {
                next();
            } else {
                subscribeApp();
            }
        }).finally(()=>{
            elementHelper.loader.hide('body_loader');
        });
    } else if(!appHelper.subscriberApp.isAppSlug()) {
        next();
    } else {
        subscribeApp();
    }
}
