import AuthService from '../../services/auth.js';
import ApiService from '../../services/api.js';
import router from "../../routers/router.js";
import {errorHelper} from "../../utils/helpers/errorHelper.js";
import {appHelper} from "../../utils/helpers/appHelper.js";

export const auth = {
    namespaced: true,
    state: () => ({
        user: null,
        email: null,
        error: null
    }),
    getters : {
        authUser: (state) => {
            return state.user;
        },
        email: (state) => {
            return state.email;
        },
        error: (state) => {
            return state.error;
        },
        loggedIn: (state) => {
            return !!state.user;
        },
        guest: () => {
            const storageItem = window.localStorage.getItem("guest");
            if (!storageItem) return false;
            if (storageItem === "isGuest") return true;
            if (storageItem === "isNotGuest") return false;
        }
    },
    mutations : {
        setUser(state, user) {
            state.user = user;
        },
        setEmail(state, email) {
            state.email = email;
        },
        setError(state, error) {
            state.error = error;
        }
    },
    actions : {
        logout({ commit, dispatch }) {
            return AuthService.logout()
                .then(() => {
                    commit("setUser", null);
                    commit("setEmail", null);
                    dispatch("setGuest", { value: "isGuest" });
                    if (router.currentRoute.name !== "login") {
                        router.push({ path: appHelper.path.login });
                    }
                })
                .catch((error) => {
                    commit('setError', errorHelper.error(error))
                });
        },
        async getAuthUser({ commit }) {
            try {
                const response = await ApiService.getAuthUser();
                commit('setUser', response.data.data)
                return response.data.data;
            } catch (error) {
                commit('setError', errorHelper.error(error))
            }
        },
        async getAuthStep({ commit }, gid) {
            try {
                const response = await AuthService.getAuthStep(gid);
                commit('setEmail', response.data.email)
                return response.data;
            } catch (error) {
                commit('setError', errorHelper.error(error))
            }
        },
        async getAuthSkip({ commit }) {
            try {
                const response = await AuthService.getAuthSkip();
                commit('setEmail', null)
                return response.data;
            } catch (error) {
                commit('setError', errorHelper.error(error))
            }
        },
        setGuest(context, { value }) {
            window.localStorage.setItem("guest", value);
        }
    }
}
