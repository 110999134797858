export const formatHelper = {
    isNumeric(str) {
        return /^\d+$/.test(str);
    },
    splitString(inputString, numParts) {
        if (inputString.length % numParts !== 0) {
            console.error("The length of the input string is not divisible by the number of parts.");
            return [];
        }

        const partLength = inputString.length / numParts;
        const parts = [];

        for (let i = 0; i < numParts; i++) {
            const startIndex = i * partLength;
            const endIndex = startIndex + partLength;
            const part = inputString.substring(startIndex, endIndex);
            parts.push(part);
        }

        return parts;
    },
    formatNumber(number) {
        // Check if the input is a valid number
        if (isNaN(number)) {
            return null;
        }

        // Convert the number to a string with '.' as the decimal separator
        let numStr = number.toString().replace(",", ".");

        // Split the number into integer and decimal parts (if any)
        const parts = numStr.split(".");

        // Format the integer part with periods (.) as thousand separators
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        // If there is a decimal part, check if it's '00' or '0'
        if (parts.length > 1 && (parts[1] === "00" || parts[1] === "0")) {
            // Omit the decimal part if it's zero
            return parts[0];
        }

        // Join the integer and decimal parts (if any)
        return parts.length === 1 ? parts[0] : parts.join(".");
    },
    numberWithoutTrailingZero(number) {
        // Check if the input is a valid number
        if (isNaN(number)) {
            return null;
        }

        // Convert the number to a string
        let numStr = number.toString().replace(",", ".");

        // Split the number into integer and decimal parts (if any)
        const parts = numStr.split(".");

        // If there is a decimal part and it's all zeros, return only the integer part
        if (parts.length > 1 && /^0+$/.test(parts[1])) {
            return parts[0]; // Return the integer part only
        }

        // Otherwise, return the full number as it is (integer and decimal)
        return numStr;
    },
    parseBoolean(value) {
        if (typeof value === 'string') {
            value = value.toLowerCase().trim();
            return value === 'true' || value === '1';
        }
        return Boolean(value);
    },
    resizeImage(input, maxWidth, maxHeight) {
        return new Promise((resolve, reject) => {
            const img = new Image();

            // Handle input types: File, URL, or base64 data:image
            if (input instanceof File) {
                const reader = new FileReader();
                reader.onload = function(event) {
                    img.src = event.target.result;
                };
                reader.readAsDataURL(input);
            } else if (typeof input === 'string' && input.startsWith('data:image/')) {
                img.src = input;
            } else if (typeof input === 'string') {
                img.src = input;
            } else {
                reject(new Error('Invalid input type. Expected a File, URL, or base64 string.'));
                return;
            }

            // Once the image is loaded, resize it
            img.onload = function() {
                // Get original image dimensions
                let originalWidth = img.width;
                let originalHeight = img.height;

                // Calculate the new width and height while maintaining the aspect ratio
                let width = originalWidth;
                let height = originalHeight;

                if (originalWidth > maxWidth || originalHeight > maxHeight) {
                    const widthRatio = maxWidth / originalWidth;
                    const heightRatio = maxHeight / originalHeight;

                    // Use the smaller ratio to ensure the image fits within the max dimensions
                    const ratio = Math.min(widthRatio, heightRatio);

                    width = originalWidth * ratio;
                    height = originalHeight * ratio;
                }

                // Create a canvas to draw the resized image
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set canvas size to the new dimensions
                canvas.width = width;
                canvas.height = height;

                // Draw the resized image on the canvas
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas to a Blob or base64 string and resolve the promise
                canvas.toBlob(function(blob) {
                    if (blob) {
                        const resizedImageUrl = URL.createObjectURL(blob);
                        resolve(resizedImageUrl);  // Resolve with the resized image URL
                    } else {
                        reject(new Error('Failed to create Blob from canvas.'));
                    }
                }, 'image/jpeg', 0.95);  // Adjust format and quality as needed
            };

            img.onerror = function() {
                reject(new Error('Failed to load the image. Please check the input.'));
            };
        });
    }
}
