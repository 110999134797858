import moment from "moment";

export const dateHelper = {
    addDay(amount, date) {
        return moment(date || undefined).add(amount, 'days');
    },
    addMonth(amount, date) {
        return moment(date || undefined).add(amount, 'months');
    },
    addYear(amount, date) {
        return moment(date || undefined).add(amount, 'years');
    },
    isDateEarlierThanNow(date) {
        // Parse the input date string using moment
        const inputDate = moment(date);
        // Check if the input date is before the current date and time
        return inputDate.isBefore(moment());
    },
    isSameDate(dateCheck, dateCompare) {
        return moment(dateCheck).isSame(moment(dateCompare), 'day');
    },
    isBeforeDate(dateCheck, dateCompare) {
        return moment(dateCheck).isBefore(moment(dateCompare), 'day');
    },
    isAfterDate(dateCheck, dateCompare) {
        return moment(dateCheck).isAfter(moment(dateCompare), 'day');
    },
    isBetweenDate(dateCheck, startDate, endDate) {
        return moment(dateCheck).isBetween(moment(startDate), moment(endDate), null, '[]') || dateHelper.isSameDate(dateCheck, startDate) || dateHelper.isSameDate(dateCheck, endDate);
    },
    dateTime : {
        default: {
            format(date) {
                return moment(date || undefined).format('YYYY-MM-DD HH:mm:ss');
            },
            display : {
                longFormat (date) {
                    return moment(date || undefined).format('DD MMMM YYYY HH:mm:ss');
                },
                shortFormat (date) {
                    return moment(date || undefined).format('DD MMM YYYY HH:mm:ss');
                },
                timeFormat (date) {
                    return moment(date || undefined).format('HH:mm:ss');
                }
            }
        },
        stringToDate(dateString) {
            // Split the string into date and time components
            const [datePart, timePart] = dateString.split(' ');
            // Split the date component into year, month, and day
            const [year, month, day] = datePart.split('-');
            // Split the time component into hours, minutes, and seconds
            const [hours, minutes, seconds] = timePart.split(':');
            // Create a Date object with the parsed components
            return new Date(year, month - 1, day, hours, minutes, seconds);
        },
        formatString(dateTimeString) {
            const now = moment();
            const targetDateTime = moment(dateTimeString);
            const diffInMinutes = now.diff(targetDateTime, 'minutes');

            if (diffInMinutes < 60) {
                return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
            } else {
                return targetDateTime.format('DD MMM YYYY HH:mm');
            }
        }
    },
    date : {
        default: {
            format(date) {
                return moment(date || undefined).format('YYYY-MM-DD');
            },
            display : {
                longFormat (date) {
                    return moment(date || undefined).format('DD MMMM YYYY');
                },
                shortFormat (date) {
                    return moment(date || undefined).format('DD MMM YYYY');
                },
                timeFormat (date) {
                    return moment(date || undefined).format('HH:mm:ss');
                }
            }
        },
        stringToDate(dateString) {
            // Split the date string into year, month, and day
            const [year, month, day] = dateString.split('-');
            // Create a Date object with the parsed components
            return new Date(year, month - 1, day);
        }
    },
    months(lang= 'en') {
        const language = window.document.documentElement.getAttribute('lang') || lang;
        const months = [];
        const lists = [
            { value: '01', label: { en: 'January', id: 'Januari' } },
            { value: '02', label: { en: 'February', id: 'Februari' } },
            { value: '03', label: { en: 'March', id: 'Maret' } },
            { value: '04', label: { en: 'April', id: 'April' } },
            { value: '05', label: { en: 'May', id: 'Mei' } },
            { value: '06', label: { en: 'June', id: 'Juni' } },
            { value: '07', label: { en: 'July', id: 'Juli' } },
            { value: '08', label: { en: 'August', id: 'Agustus' } },
            { value: '09', label: { en: 'September', id: 'September' } },
            { value: '10', label: { en: 'October', id: 'Oktober' } },
            { value: '11', label: { en: 'November', id: 'November' } },
            { value: '12', label: { en: 'December', id: 'Desember' } }
        ];

        Array.prototype.filter.call(lists, list=>{
            months.push({ value : list.value, label : list.label[language]});
        });

        return months;
    },
    days() {
        const days = [];
        for (let i = 1; i <= 31; i++) {
            const dayValue = i < 10 ? `0${i}` : `${i}`; // Add leading zero if necessary
            days.push({ value: dayValue, label: dayValue });
        }
        return days;
    },
    hours() {
        const hours = [];
        for (let i = 0; i <= 23; i++) {
            const dayValue = i < 10 ? `0${i}` : `${i}`; // Add leading zero if necessary
            hours.push({ value: dayValue, label: dayValue });
        }
        return hours;
    },
    minutes() {
        const minutes = [];
        for (let i = 0; i <= 59; i++) {
            const dayValue = i < 10 ? `0${i}` : `${i}`; // Add leading zero if necessary
            minutes.push({ value: dayValue, label: dayValue });
        }
        return minutes;
    },
    dayNames(lang = 'en') {
        const language = window.document.documentElement.getAttribute('lang') || lang;
        const englishWeekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const indonesianWeekdays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
        if (language === 'id') {
            return indonesianWeekdays;
        } else {
            return englishWeekdays;
        }
    },
    shortDayNames(lang = 'en') {
        const language = window.document.documentElement.getAttribute('lang') || lang;
        const englishWeekdaysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const indonesianWeekdaysShort = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];
        if (language === 'id') {
            return indonesianWeekdaysShort;
        } else {
            return englishWeekdaysShort;
        }
    }
}
