import {AdminModules} from "./module.js";
import {AdminUsers} from "./user.js";
import {AdminMatrixApproval} from "./matrix-approval.js";
import {AdminDefaults} from "./default.js";
import {AdminRegions} from "./region.js";
import {AdminSettings} from "./setting.js";

export const AdminRouters = [
    ...AdminDefaults,
    ...AdminModules,
    ...AdminUsers,
    ...AdminMatrixApproval,
    ...AdminRegions,
    ...AdminSettings
];
