/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 22/03/2024
 */

import auth from '../../../middleware/auth.js';
import shortcuts from '../../../middleware/shortcuts.js';
import {appHelper} from "../../../utils/helpers/appHelper.js";
import {AmsAppCorporation} from "./corporation.js";

export const AmsAppRegions = [
    {
        path: appHelper.path.amsApp + '/region/country',
        name: 'amsAppCountry',
        component: () => import('../../region/views/country/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/region/state',
        name: 'amsAppState',
        component: () => import('../../region/views/state/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/region/city',
        name: 'amsAppCity',
        component: () => import('../../region/views/city/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/region/regional',
        name: 'amsAppRegional',
        component: () => import('../../region/views/regional/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/region/sub-regional',
        name: 'amsAppSubRegional',
        component: () => import('../../region/views/sub-regional/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    }
];
