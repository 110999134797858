/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 22/03/2024
 */

import auth from '../../../middleware/auth.js';
import shortcuts from '../../../middleware/shortcuts.js';
import {appHelper} from "../../../utils/helpers/appHelper.js";
export const AdminRegions = [
    {
        path: appHelper.path.adminApp + '/region',
        component: () => import('../../../views/layout/NavTab.vue'),
        meta: { middleware: [auth, shortcuts] },
        children: [
            {
                path: 'currency',
                name: 'adminAppCurrency',
                component: () => import('../../region/views/currency/index.vue')
            },
            {
                path: 'country',
                name: 'adminAppCountry',
                component: () => import('../../region/views/country/index.vue')
            },
            {
                path: 'state',
                name: 'adminAppState',
                component: () => import('../../region/views/state/index.vue')
            },
            {
                path: 'city',
                name: 'adminAppCity',
                component: () => import('../../region/views/city/index.vue')
            },
            {
                path: 'regional',
                name: 'adminAppRegional',
                component: () => import('../../region/views/regional/index.vue')
            },
            {
                path: 'sub-regional',
                name: 'adminAppSubRegional',
                component: () => import('../../region/views/sub-regional/index.vue')
            }
        ]
    }
];
