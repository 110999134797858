import auth from '../../../middleware/auth.js';
import shortcuts from '../../../middleware/shortcuts.js';
import {appHelper} from "../../../utils/helpers/appHelper.js";
export const AdminMatrixApproval = [
    {
        path: appHelper.path.adminApp + '/admin/matrix-approval',
        component: () => import('../views/matrix-approval/home.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'matrix-approval',
                path: '',
                component: () => import('../views/matrix-approval/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'tpl-approval',
                path: 'tpl/:id',
                component: () => import('../views/matrix-approval/flow.vue'),
                meta: { title:'', middleware: [auth, shortcuts] }
            }
        ]
    }
];
