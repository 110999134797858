<template>
    <li v-if="$store.getters['menu/shortcuts'].length>=2" class="nav-item dropdown-shortcuts navbar-dropdown dropdown me-2 me-xl-0">
        <a
            class="nav-link dropdown-toggle hide-arrow"
            href="javascript:void(0);"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="handleClick"
        >
            <i class="ti ti-layout-grid-add ti-md"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end py-0">
            <div class="dropdown-menu-header border-bottom">
                <div class="dropdown-header d-flex align-items-center py-3">
                    <h5 class="text-body mb-0 me-auto">Shortcuts</h5>
                    <a
                        href="javascript:void(0)"
                        class="dropdown-shortcuts-add text-body"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Add shortcuts"
                    ><i class="ti ti-sm ti-apps"></i
                    ></a>
                </div>
            </div>
            <div class="dropdown-shortcuts-list scrollable-container">
                <div class="row row-bordered overflow-visible g-0">
                    <div
                        v-for="(item, index) in $store.getters['menu/shortcuts']"
                        class="dropdown-shortcuts-item"
                        :class="((index+1)===$store.getters['menu/shortcuts'].length?(((index+1)%2)===1?'col':'col-6'):'col-6')"
                    >
                        <span class="dropdown-shortcuts-icon rounded-circle mb-2">
                            <i class="fs-4" :class="[(item.icon_type==='tabler'?'ti ti-':'bi bi-') + (item.icon_class?item.icon_class:'apps')]" ></i>
                        </span>
                        <router-link :to="getHomePath(item) + (item.route_direct?item.route_direct:item.route_frontend)" class="stretched-link">{{ item.shortname }}</router-link>
                        <small class="text-muted mb-0">{{ item.description }}</small>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import {appHelper} from "../../utils/helpers/appHelper.js";
import shortcuts from "../../middleware/shortcuts.js";

export default {
    name: "shortcuts",
    mounted() {
        // setTimeout(this.pageIsHasMainMenu, 1000);
    },
    unmounted() {
        if(this.pscrollBar) {
            this.pscrollBar.destroy();
            this.pscrollBar = null;
        }
    },
    methods: {
        handleClick() {
            if(!this.pscrollBar) {
                if(this.$store.getters['menu/shortcuts'].length>=7) {
                    const element = this.$el.querySelector('.scrollable-container');
                    this.pscrollBar = new window.PerfectScrollbar(element, {
                        wheelPropagation: false
                    });
                }
            }
        },
        getHomePath(item) {
            if(item.route_type) {
                return '';
            }

            return appHelper.path.home;
        },
        async pageIsHasMainMenu() {
            if(!this.$store.getters['menu/shortcutRequest']) {
                let isAssignShortcuts = false;
                if (this.$route.meta.hasOwnProperty('middleware')) {
                    this.$route.meta.middleware.forEach(middleware => {
                        if (middleware.name === 'shortcuts') {
                            isAssignShortcuts = true;
                        }
                    });
                }

                if (!isAssignShortcuts) {
                    await this.$store.dispatch("menu/getShortcuts");
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
