import auth from "../middleware/auth.js";
import guest from "../middleware/guest.js";
import verify from "../middleware/verify.js";
import resetPwd from "../middleware/resetPwd.js";
import shortcuts from "../middleware/shortcuts.js";
import {AdminRouters} from "../modules/admin/routers";
import {TmsRouters} from "../modules/tms/routers";
import {UserRouters} from "./inc/UserRouters.js";
import {appHelper} from "../utils/helpers/appHelper.js";
import {TaskAppRouters} from "../modules/taskapp/routers/index.js";
import {HrAppRouters} from "../modules/hrapp/routers/index.js";
import {AmsAppRouters} from "../modules/amsapp/routers/index.js";

const DefaultRouters = [
    {
        path: '/:pathMatch(.*)*',
        name: 'errorPage',
        component: () => import('../views/misc/ErrorPage.vue')
    },
    {
        path: appHelper.path.home + '/error-page',
        name: 'errorPageImported',
        component: () => import('../views/misc/ErrorPage.vue')
    },
    {
        path: appHelper.path.home,
        name: 'home',
        component: () => import('../views/home/Home.vue'),
        meta: { middleware: [auth, shortcuts], directShortcut:true }
    },
    {
        path: appHelper.path.login,
        name: 'login',
        component: () => import('../views/auth/Login.vue'),
        meta: { middleware: [guest] }
    },
    {
        path: appHelper.path.home + '/forgot-password',
        name: 'forgotPassword',
        component: () => import('../views/auth/ForgotPassword.vue'),
        meta: { middleware: [guest] }
    },
    {
        path: appHelper.path.home + '/email/verify',
        name: 'verifyEmail',
        component: () => import('../views/auth/MustVerifyEmail.vue'),
        meta: { middleware: [verify] }
    },
    {
        path: appHelper.path.home + '/email/verify/:gid',
        name: 'confirmEmail',
        component: () => import('../views/auth/ConfirmEmail.vue'),
        meta: { middleware: [verify] }
    },
    {
        path: appHelper.path.home + '/2fa-email',
        name: 'twoStepVerificationEmail',
        component: () => import('../views/auth/TwoStepVerificationEmail.vue'),
        meta: { middleware: [verify] }
    },
    {
        path: appHelper.path.home + '/2fa-token',
        name: 'twoStepVerification',
        component: () => import('../views/auth/TwoStepVerification.vue'),
        meta: { middleware: [verify] }
    },
    {
        path: appHelper.path.home + '/reset-password/:token',
        name: 'resetPassword',
        component: () => import('../views/auth/ResetPassword.vue'),
        meta: { middleware: [resetPwd] }
    }
]

if(import.meta.env.VITE_USER_REGISTER==='true') {
    DefaultRouters.push({
        path: appHelper.path.home + '/register',
        name: 'register',
        component: () => import('../views/auth/Register.vue'),
        meta: { middleware: [guest] }
    });
}

export const AppRouters = [
    ...DefaultRouters,
    ...UserRouters,
    ...AdminRouters,
    ...TmsRouters,
    ...TaskAppRouters,
    ...HrAppRouters,
    ...AmsAppRouters
];
