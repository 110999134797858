/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 24/10/2024
 */
import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

export const HrAppCorporation = [
    {
        path: appHelper.path.hrApp + '/corporation',
        name:'hrAppCorporation',
        component: () => import('../views/corporation/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.hrApp + '/org/position',
        name:'hrAppPosition',
        component: () => import('../views/position/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    }
]
