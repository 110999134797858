import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 01/11/2024
 */
export const AmsAppCorporation = [
    {
        path: appHelper.path.amsApp + '/corporation',
        name:'amsAppCorporation',
        component: () => import('../../hrapp/views/corporation/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/org/position',
        name:'amsAppPosition',
        component: () => import('../../hrapp/views/position/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    }
];
