/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 02/04/2024
 */

import auth from '../../../middleware/auth.js';
import shortcuts from '../../../middleware/shortcuts.js';
import {appHelper} from "../../../utils/helpers/appHelper.js";

export const AdminSettings = [
    {
        name: 'setting',
        path: appHelper.path.adminApp + '/setting',
        component: () => import('../views/settings/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    }
];
