import {appHelper} from "./appHelper.js";
import {connectionHelper} from "./connectionHelper.js";
import axios from "axios";
export const errorHelper = {
    errorData(error) {
        const errorMessage = "API Error, please try again.";
        if (!error.response) {
            if (appHelper.environment.development) {
                console.error('api', (error.config || null) ? `API ${error.config.url} not found` : '');
            }
            connectionHelper.bsToast.show(errorMessage);
            return errorMessage;
        }

        if (error.response.isCancel) {
            if (appHelper.environment.development) {
                console.warn('Request was aborted:', error);
            }
            return "Request was aborted.";
        }

        if (appHelper.environment.development) {
            console.error('error.response.data = ', error.response.data);
            console.error('error.response.status = ', error.response.status);
            console.error('error.response.headers = ', error.response.headers);
        }

        if (error.response.data) {
            return error.response.data;
        }

        connectionHelper.bsToast.show(errorMessage);
        return errorMessage;
    },
    error(error) {
        const data = errorHelper.errorData(error);
        if(data.errors) {
            return data.errors;
        }
        return data;
    },
    errorMessage(error) {
        const data = errorHelper.errorData(error);
        if(data.message) {
            return errorHelper.removeAdditionalErrors(data.message);
        }
        return data;
    },
    removeAdditionalErrors(errorMessage) {
        // Use a regular expression to match the pattern and extract the number
        let match = errorMessage.match(/\(and (\d+) more errors\)/);

        // Check if the pattern is found
        if (match) {
            const numberOfErrors = match[1]; // Extract the number from the match
            const pattern = new RegExp(`\\(and ${numberOfErrors} more errors\\)`, 'g');

            // Remove the identified pattern from the error message
            return errorMessage.replace(pattern, '');
        } else {
            match = errorMessage.match(/\(and (\d+) more error\)/);
            if (match) {
                const numberOfErrors = match[1]; // Extract the number from the match
                const pattern = new RegExp(`\\(and ${numberOfErrors} more error\\)`, 'g');

                // Remove the identified pattern from the error message
                return errorMessage.replace(pattern, '');
            } else {
                return errorMessage;
            }
        }
    },
    modal: {
        auth : {
            modal(message, textColorClass) {
                if(message) {
                    let element = document.body.querySelector('.helper-modal-auth');
                    if(element) {
                        element.remove();
                    }

                    element = document.createElement('div');
                    element.classList.add('modal');
                    element.classList.add('fade');
                    element.classList.add('helper-modal-auth');
                    element.innerHTML = '<div class="modal-dialog modal-sm modal-dialog-centered">\n' +
                        '                <div class="modal-content">\n' +
                        '                    <div class="modal-body ' + textColorClass + ' py-3">' + message + '</div>\n' +
                        '                </div>\n' +
                        '            </div>';
                    document.body.appendChild(element);
                    (new bootstrap.Modal(element)).show();
                }
            },
            danger(message) {
                errorHelper.modal.auth.modal(message, 'text-danger');
            },
            primary(message) {
                errorHelper.modal.auth.modal(message, 'text-primary');
            },
            success(message) {
                errorHelper.modal.auth.modal(message, 'text-success');
            }
        },

    }
}
