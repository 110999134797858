import { appHelper } from "../utils/helpers/appHelper.js";
import { menuHelper } from "../utils/helpers/menuHelper.js";
import router from "../routers/router.js";
import {elementHelper} from "../utils/helpers/elementHelper.js";

export default async function shortcuts({to, next, store}) {
    let shortcuts = store.getters["menu/shortcuts"];
    if (!shortcuts.length) {
        elementHelper.loader.show('body_loader', window.document.body);
        await store.dispatch("menu/getShortcuts").then(async () => {
            shortcuts = store.getters["menu/shortcuts"];
            if (shortcuts.length && to.meta.hasOwnProperty('directShortcut')) {
                if (to.meta.directShortcut && (shortcuts[0].route_direct || shortcuts[0].route_frontend) && shortcuts[0].route_direct !== '/') {
                    router.push({path: (shortcuts[0].route_type ? '' : appHelper.path.home) + (shortcuts[0].route_direct ? shortcuts[0].route_direct : shortcuts[0].route_frontend)});
                } else {
                    await menuHelper.middleware(to);
                    next();
                }
            } else {
                await menuHelper.middleware(to);
                next();
            }
        }).finally(()=>{
            elementHelper.loader.hide('body_loader');
        });
    } else {
        if (shortcuts.length && to.meta.hasOwnProperty('directShortcut')) {
            if (to.meta.directShortcut && (shortcuts[0].route_direct || shortcuts[0].route_frontend) && shortcuts[0].route_direct!=='/') {
                router.push({path: (shortcuts[0].route_type?'':appHelper.path.home) + (shortcuts[0].route_direct ? shortcuts[0].route_direct : shortcuts[0].route_frontend)});
            } else {
                await menuHelper.middleware(to);
                next();
            }
        } else {
            await menuHelper.middleware(to);
            next();
        }
    }
}
