/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 01/11/2024
 */
import {AmsAppDefaults} from "./default.js";
import {AmsAppCorporation} from "./corporation.js";
import {AmsAppRegions} from "./region.js";
import {AmsAppChannels} from "./channel.js";
import {AmsAppProducts} from "./product.js";
import {AmsAppEmployee} from "./employee.js";

export const AmsAppRouters = [
    ...AmsAppDefaults,
    ...AmsAppCorporation,
    ...AmsAppRegions,
    ...AmsAppChannels,
    ...AmsAppProducts,
    ...AmsAppEmployee
];
