/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 24/10/2024
 */
import {HrAppDefaults} from "./default.js";
import {HrAppCorporation} from "./corporation.js";
import {HrAppEmployee} from "./employee.js";
import {HrAppRegions} from "./region.js";


export const HrAppRouters = [
    ...HrAppDefaults,
    ...HrAppCorporation,
    ...HrAppEmployee,
    ...HrAppRegions
]
