/**
 * Created By: Andriyanto.
 * E-mail Address: 4lenour@gmail.com
 * Date: 09/02/2024
 * Time: 18:35
 */

export const arrayHelper = {
    isArray(array) {
        return Array.isArray(array);
    },
    inArray(array, value) {
        return (array.indexOf(value) !== -1);
    },
    deleteValue(array, value) {
        const index = array.indexOf(value);
        if (index !== -1) {
            array.splice(index, 1);
        }
        return [...array];
    },
    deleteIndex(array, index) {
        array.splice(index, 1);
        return [...array];
    },
    insertAtIndex(array, index, element, resultNewArray = false) {
        if(resultNewArray) {
            const newArray = [...array];
            newArray.splice(index, 0, element);
            return newArray;
        } else {
            array.splice(index, 0, element);
            return array;
        }
    },
    deleteByProperty(array, value, property = 'id') {
        const index = array.findIndex(item => item[property] === value);
        if (index !== -1) {
            array.splice(index, 1);
        }
        return [...array];
    }
}
