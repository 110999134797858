import { useLoading } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export const elementHelper = {
    loader: {
        activeLoader : {},
        show(loaderId, container, width=48, height=48, loader= 'spinner') {
            const objectProps = useLoading({
                color: '#7367f0',
                loader: loader,
                backgroundColor: '#ffffff',
                opacity: 0.6
            });

            elementHelper.loader.hide(loaderId);
            elementHelper.loader.activeLoader[loaderId] = objectProps.show({
                container: container,
                width: width,
                height: height
            });
        },
        hide(loaderId) {
            if(elementHelper.loader.activeLoader.hasOwnProperty(loaderId)) {
                elementHelper.loader.activeLoader[loaderId].hide();
                delete elementHelper.loader.activeLoader[loaderId];
            }
        }
    },
    passwordToggle(element) {
        const formPasswordToggle = element.closest('.form-password-toggle')
        const formPasswordToggleIcon = formPasswordToggle.querySelector('i');
        const formPasswordToggleInput = formPasswordToggle.querySelector('input');
        if (formPasswordToggleInput.getAttribute('type') === 'text') {
            formPasswordToggleInput.setAttribute('type', 'password')
            formPasswordToggleIcon.classList.replace('ti-eye', 'ti-eye-off')
        } else if (formPasswordToggleInput.getAttribute('type') === 'password') {
            formPasswordToggleInput.setAttribute('type', 'text')
            formPasswordToggleIcon.classList.replace('ti-eye-off', 'ti-eye')
        }
    },
    getIndexInParent(element) {
        // Get the parent element
        const parent = element.parentElement;

        // Get the index of the element within its parent's children
        return Array.from(parent.children).indexOf(element);
    },
    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    getRandomFontColor(backgroundColor) {
        // Convert the hex color to RGB
        const rgb = parseInt(backgroundColor.substring(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;

        // Calculate the luminance
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Choose white or black based on the luminance
        return luminance > 0.5 ? '#000000' : '#ffffff';
    },
    getWindowWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    },
    getWindowHeight() {
        return window.innerHeight|| document.documentElement.clientHeight || document.body.clientHeight;
    },
    windowPopup(url, target, width, height) {
        // Calculate the position of the popup window to center it
        const left = (screen.width / 2) - (width / 2);
        const top = (screen.height / 2) - (height / 2);

        // Create a string with the window features
        const windowFeatures = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`;

        // Open the new window
        const newWindow = window.open(url, target, windowFeatures);

        // Give focus to the new window
        if (window.focus) {
            newWindow.focus();
        }

        return newWindow;
    },
    setZIndexLastModal() {
        const ziModal = 1090
        const ziBackdrop = 1089
        const modals = document.querySelectorAll('.modal.form-modal.show');
        const backdrops = document.querySelectorAll('.modal-backdrop');

        if (modals.length > 1) {
            modals[modals.length-1].style.zIndex = ziModal+modals.length;
        }

        if (backdrops.length > 1) {
            backdrops[backdrops.length-1].style.zIndex = ziBackdrop+backdrops.length;
        }
    }
}
