import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 01/11/2024
 */
export const AmsAppDefaults = [
    {
        path: appHelper.path.amsApp,
        name:'amsApp',
        component: () => import('../views/dashboard/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    }
];
