/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 31/08/2024
 */
import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

export const TaskAppDefaults = [
    {
        path: appHelper.path.taskApp,
        name:'taskApp',
        component: () => import('../views/home/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'taskAppSetting',
        path: appHelper.path.taskApp + '/setting',
        component: () => import('../views/setting/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'taskAppEmail',
        path: appHelper.path.taskApp + '/email',
        component: () => import('../views/email/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'taskAppWorkspace',
        path: appHelper.path.taskApp + '/workspace',
        component: () => import('../views/workspace/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
]
