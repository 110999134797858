/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 21/03/2024
 */

import auth from '../../../middleware/auth.js';
import shortcuts from '../../../middleware/shortcuts.js';
import {appHelper} from '../../../utils/helpers/appHelper.js';

export const AdminDefaults = [
    {
        name: 'cache',
        path: appHelper.path.adminApp + '/cache',
        component: () => import('../views/cache/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'customer-personal',
        path: appHelper.path.adminApp + '/customer/personal',
        component: () => import('../../customer/views/personal/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'customer-company',
        path: appHelper.path.adminApp + '/customer/company',
        component: () => import('../../customer/views/company/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'subscriber',
        path: appHelper.path.adminApp + '/subscriber',
        component: () => import('../views/subscriber/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    }
]
