import {appHelper} from "../utils/helpers/appHelper.js";
import {geoKey} from "../utils/geoKey.js";
import store from "../stores/store.js";
import axios from 'axios';
import {connectionHelper} from "../utils/helpers/connectionHelper.js";

export const apiMultipart = axios.create({
    baseURL: appHelper.url.api(),
    timeout: 3600000,
    withCredentials : true
});

const SLOW_CONNECTION_THRESHOLD = 10000; // 1 second

apiMultipart.interceptors.request.use(
    (config) => {
        const token = geoKey.getTKey();
        config.headers['Content-Type'] = 'multipart/form-data';
        config.headers['Access-Control-Allow-Credentials'] = true;
        config.headers['Authorization'] = (token ? `Bearer ${token}` : '');
        config.headers['app-gid'] = geoKey.getIKey();
        config.metadata = { startTime: new Date() };
        connectionHelper.onLine();
        return config;
    },
    (error) => {
        connectionHelper.onLine();
        return Promise.reject(error);
    }
);

// Add a response interceptor
apiMultipart.interceptors.response.use( (response) => {
        const duration = new Date() - response.config.metadata.startTime; // Calculate duration
        if (duration > SLOW_CONNECTION_THRESHOLD) {
            connectionHelper.bsToast.show(`Slow connection detected: ${duration} ms`);
        }
        return response;
    },  (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if ( error.response && [401, 419].includes(error.response.status) && store.getters["auth/authUser"] && !store.getters["auth/guest"] ) {
            store.dispatch("auth/logout");
        } else if ( error.response && [401].includes(error.response.status) && !store.getters["auth/guest"] ) {
            store.dispatch("auth/logout");
        }

        connectionHelper.onLine();
        error.response.isCancel = !!(axios.isCancel(error) || error.message.includes('BS_BINDING_ABORTED') || error.name === 'AbortError' || error.code === 'ECONNABORTED' || error.message === 'BS_BINDING_ABORTED');
        return Promise.reject(error);
    }
);
