export const stringHelper = {
    capitalizeFirstLetter(str) {
        if(!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    splitWhitespaceToArray(str) {
        return str.trim() ? str.trim().split(/\s+/) : '';
    },
    toPlural(singular, pluralForm = null) {
        if (pluralForm) {
            return pluralForm; // For irregular plural forms
        }
        // Regular pluralization rules
        if (singular.endsWith('y') && !/[aeiou]y$/.test(singular)) {
            return singular.slice(0, -1) + 'ies';  // e.g., "baby" -> "babies"
        } else if (singular.endsWith('s') || singular.endsWith('sh') || singular.endsWith('ch')) {
            return singular + 'es';  // e.g., "class" -> "classes"
        } else {
            return singular + 's';  // Default rule: append "s"
        }
    },
    toSingular(plural, singularForm = null) {
        if (singularForm) {
            return singularForm; // For irregular singular forms
        }
        // Regular singularization rules
        if (plural.endsWith('ies')) {
            return plural.slice(0, -3) + 'y';  // e.g., "babies" -> "baby"
        } else if (plural.endsWith('es') && (plural.endsWith('shes') || plural.endsWith('ches') || plural.endsWith('sses'))) {
            return plural.slice(0, -2);  // e.g., "classes" -> "class"
        } else if (plural.endsWith('s')) {
            return plural.slice(0, -1);  // Default rule: remove "s"
        }
        return plural; // Return as-is if it doesn't match any rule
    }
}
