import {apiClient} from "./apiClient.js";
export default  {
    async setPassword(payload) {
        return apiClient.post("/user/password", payload);
    },
    async getTwoFactor() {
        return apiClient.get("/user/two-factor");
    },
    async getRecentDevices(perPage=10) {
        return apiClient.get("/user/recent-device?perpage=" + perPage.toString());
    },
    async setTwoFactor() {
        return apiClient.post("/user/two-factor/activate");
    },
    async unsetTwoFactor() {
        return apiClient.post("/user/two-factor/deactivate");
    },
    async confirmTwoFactor(payload) {
        return apiClient.post("/user/two-factor/confirm", payload);
    },
    async getProfile() {
        return apiClient.get("/user/profile");
    },
    async setProfile(payload) {
        return apiClient.post("/user/profile", payload);
    },
    async getPrimaryAddress() {
        return apiClient.get("/user/address/primary-address");
    },
    async setPrimaryAddress(payload) {
        return apiClient.post("/user/address/primary-address", payload);
    },
    async getBillingAddress() {
        return apiClient.get("/user/address/billing-address");
    },
    async setBillingAddress(payload) {
        return apiClient.post("/user/address/billing-address", payload);
    },
    async getCompany() {
        return apiClient.get("/user/company");
    },
    async setCompany(payload) {
        return apiClient.post("/user/company", payload);
    },
    async logoutAs() {
        return apiClient.post("/user/logout-as");
    }
}
