/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 31/08/2024
 */
import {TaskAppDefaults} from "./default.js";

export const TaskAppRouters = [
    ...TaskAppDefaults
];
