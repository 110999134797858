import {apiClient} from "./apiClient.js";
import {apiMultipart} from "./apiMultipart.js";

export default {
    getAbortController() {
        return new AbortController();
    },
    getAuthUser() {
        return apiClient.get("/user/auth");
    },
    get(url, abortCtrl) {
        return apiClient.get(url, ( abortCtrl ? {signal:abortCtrl.signal} : null ));
    },
    post(url, payload, abortCtrl) {
        return apiClient.post(url, ( payload?payload:null ), ( abortCtrl ? {signal:abortCtrl.signal} : null ));
    },
    upload(url, payload, abortCtrl) {
        return apiMultipart.post(url, ( payload?payload:null ), ( abortCtrl ? {signal:abortCtrl.signal} : null ));
    }
}
