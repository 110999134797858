import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";
import {appHelper} from "../../../utils/helpers/appHelper.js";

/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 17/12/2024
 */
export const AmsAppEmployee = [
    {
        path: appHelper.path.amsApp + '/employee/blood',
        name: 'amsAppHrBlood',
        component: () => import('../../hrapp/views/blood/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/employee/religion',
        name: 'amsAppHrReligion',
        component: () => import('../../hrapp/views/blood/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/employee/marital',
        name: 'amsAppHrMarital',
        component: () => import('../../hrapp/views/blood/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/employee/education',
        name: 'amsAppHrEducation',
        component: () => import('../../hrapp/views/blood/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    }
];
