import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

export const AmsAppChannels = [
    {
        path: appHelper.path.amsApp + '/distribution/channel',
        name: 'amsAppChannel',
        component: () => import('../views/channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            formTitle: 'Channel'
        }
    },
    {
        path: appHelper.path.amsApp + '/distribution/channel/sub-channel',
        name: 'amsAppSubChannel',
        component: () => import('../views/sub-channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            gridTitle: 'Sub Channels',
            formTitle: 'Sub Channel',
            fieldParent : {
                label :'Channel',
                fieldForm:'channel_parent',
                fieldColumn :'parent_name',
                sourceDropdown :'/amsapp/distribution/channel',
            }
        }
    },
    {
        path: appHelper.path.amsApp + '/distribution/chain',
        name: 'amsAppChain',
        component: () => import('../views/channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            formTitle: 'Chain'
        }
    },
    {
        path: appHelper.path.amsApp + '/distribution/chain/sub-chain',
        name: 'amsAppSubChain',
        component: () => import('../views/sub-channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            gridTitle: 'Sub Chains',
            formTitle: 'Sub Chain',
            fieldParent : {
                label :'Chain',
                fieldForm:'chain_parent',
                fieldColumn :'parent_name',
                sourceDropdown :'/amsapp/distribution/chain',
            }
        }
    },
    {
        path: appHelper.path.amsApp + '/distribution/store',
        name: 'amsAppStore',
        component: () => import('../views/store/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/product/category',
        name: 'amsAppCategory',
        component: () => import('../views/channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            gridTitle: 'Categories',
            formTitle: 'Category'
        }
    },
    {
        path: appHelper.path.amsApp + '/product/category/product-type',
        name: 'amsAppProductType',
        component: () => import('../views/channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            gridTitle: 'Product Types',
            formTitle: 'Product Type'
        }
    },
    {
        path: appHelper.path.amsApp + '/product/category/sub-category',
        name: 'amsAppSubCategory',
        component: () => import('../views/sub-channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            gridTitle: 'Sub Categories',
            formTitle: 'Sub Category',
            fieldParent : {
                label :'Category',
                fieldForm:'category_parent',
                fieldColumn :'parent_name',
                sourceDropdown :'/amsapp/product/category',
            }
        }
    },
    {
        path: appHelper.path.amsApp + '/product/brand',
        name: 'amsAppBrand',
        component: () => import('../views/channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            formTitle: 'Brand'
        }
    },
    {
        path: appHelper.path.amsApp + '/product/brand/sub-brand',
        name: 'amsAppSubBrand',
        component: () => import('../views/sub-channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            gridTitle: 'Brand Products',
            formTitle: 'Brand Product',
            fieldParent : {
                label :'Brand',
                fieldForm:'brand_parent',
                fieldColumn :'parent_name',
                sourceDropdown :'/amsapp/product/brand',
            }
        }
    }
];
