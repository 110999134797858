/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 22/03/2024
 */

import auth from '../../../middleware/auth.js';
import shortcuts from '../../../middleware/shortcuts.js';
import {appHelper} from "../../../utils/helpers/appHelper.js";

export const HrAppRegions = [
    {
        path: appHelper.path.hrApp + '/region',
        component: () => import('../../../views/layout/NavTab.vue'),
        meta: { middleware: [auth, shortcuts] },
        children: [
            {
                path: 'currency',
                name: 'hrAppCurrency',
                component: () => import('../../region/views/currency/index.vue')
            },
            {
                path: 'country',
                name: 'hrAppCountry',
                component: () => import('../../region/views/country/index.vue')
            },
            {
                path: 'state',
                name: 'hrAppState',
                component: () => import('../../region/views/state/index.vue')
            },
            {
                path: 'city',
                name: 'hrAppCity',
                component: () => import('../../region/views/city/index.vue')
            }
        ]
    }
];
