import auth from '../../../middleware/auth.js';
import shortcuts from '../../../middleware/shortcuts.js';
import {appHelper} from "../../../utils/helpers/appHelper.js";
export const AdminModules = [
    {
        path: appHelper.path.adminApp + '/module',
        component: () => import('../../../views/layout/tabScrollbarLayout.vue'),
        meta: { middleware: [auth] },
        children: [
            {
                name: 'module',
                path: '',
                component: () => import('../views/modules/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'module-features',
                path: 'feature',
                component: () => import('../views/features/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'category',
                path: 'category',
                component: () => import('../views/categories/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'permission',
                path: 'permission',
                component: () => import('../views/permissions/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'app-menu',
                path: 'app-menu',
                component: () => import('../views/app-menu/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            },
            {
                name: 'mobile-menu',
                path: 'mobile-menu',
                component: () => import('../views/mobile-menu/index.vue'),
                meta: { middleware: [auth, shortcuts] }
            }
        ]
    }
];
