<template>
    <ul>
        <li v-for="item in items" class="menu-item" :class="((item.focus||null)?'active':'')">
            <a
                v-if="item.menu_type!=='tab' && (!item.route_frontend || item.menus.length)"
                href="javascript:void(0)"
                class="menu-link"
                :class="(item.menus.length?(item.menu_type!=='tab'?(item.menu_type!=='megamenu'?'menu-toggle':'menu-toggle mega-menu-sub'):''):'')"
            >
                <i v-if="item.icon_class" class="menu-icon tf-icons ti ti-home" :class="(item.icon_type==='tabler' ? 'ti ti-' : 'bi bi-') + item.icon_class"></i>
                <div>{{ item.name }}</div>
            </a>
            <router-link
                v-else-if="!(item.menu_type!=='tab' && (!item.route_frontend || item.menus.length))"
                :to="getHomePath(item) + item.route_frontend"
                class="menu-link"
                :class="(item.menus.length?(item.menu_type!=='tab'?(item.menu_type!=='megamenu'?'menu-toggle':'menu-toggle mega-menu-sub'):''):'')"
            >
                <i v-if="item.icon_class" class="menu-icon tf-icons ti ti-home" :class="(item.icon_type==='tabler' ? 'ti ti-' : 'bi bi-') + item.icon_class"></i>
                <div>{{ item.name }}</div>
            </router-link>
            <HorizontalMenuItem
                v-if="item.menus.length && item.menu_type!=='tab'"
                :items="item.menus"
                :class="getMegaMenuWidthAutoClass(item.menus)"
            />
        </li>
    </ul>
</template>

<script>
import {appHelper} from "../../utils/helpers/appHelper.js";

export default {
    name: "HorizontalMenuItem",
    props: {
        items: {
            type: Array,
            default : []
        }
    },
    methods : {
        getMegaMenuWidthAutoClass(menus) {
            let className = 'menu-sub';
            if(menus.length) {
                for (let i = 0; i < menus.length; i++) {
                    if(menus[i].menu_type==='megamenu') {
                        className = 'menu-sub w-megamenu';
                        break;
                    }
                }
            }
            return className;
        },
        getHomePath(element) {
            if(element.route_type) {
                return '';
            }

            return appHelper.path.home;
        }
    }
}
</script>

<style scoped>

</style>
