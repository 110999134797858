import './bootstrap';
import { createApp } from "vue/dist/vue.esm-bundler";
import router from "./routers/router";
import store from "./stores/store";
import gpApp from "./utils/plugins/gpApp.js";
import gpUtils from "./utils/plugins/gpUtils.js";
import broadcast from "./utils/plugins/broadcast.js";
import MyApp from "./views/MyApp.vue";
const app = createApp({
    components : {
        'myapp' : MyApp
    },
});
app.use(gpApp);
app.use(store);
app.use(router);
app.use(broadcast);
app.use(gpUtils);
app.mount('#myapp');
