import { appHelper } from "../utils/helpers/appHelper.js";
import store from "../stores/store.js";
import axios from 'axios';
import {connectionHelper} from "../utils/helpers/connectionHelper.js";

export const authClient = axios.create({
    baseURL: appHelper.url.auth(),
    timeout: 3600000,
    withCredentials : true,
    withXSRFToken : true
});

const SLOW_CONNECTION_THRESHOLD = 10000; // 1 second

authClient.interceptors.request.use(
    (config) => {
        config.headers['Content-Type'] = 'application/json';
        config.headers['Access-Control-Allow-Credentials'] = true;
        // Check if the request is to get the CSRF cookie, modify the base URL

        if (config.url === "/sanctum/csrf-cookie") {
            config.baseURL = appHelper.url.auth(true); // Replace with new base URL for CSRF
        }

        config.metadata = { startTime: new Date() };
        connectionHelper.onLine();
        return config;
    },
    (error) => {
        connectionHelper.onLine();
        return Promise.reject(error);
    }
);

// Add a response interceptor
authClient.interceptors.response.use( (response) => {
        const duration = new Date() - response.config.metadata.startTime; // Calculate duration
        if (duration > SLOW_CONNECTION_THRESHOLD) {
            connectionHelper.bsToast.show(`Slow connection detected: ${duration} ms`);
        }
        return response;
    },  (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if ( error.response && [401, 419].includes(error.response.status) && store.getters["auth/authUser"] && !store.getters["auth/guest"] ) {
            store.dispatch("auth/logout");
        }

        connectionHelper.onLine();
        error.response.isCancel = !!(axios.isCancel(error) || error.message.includes('BS_BINDING_ABORTED') || error.name === 'AbortError' || error.code === 'ECONNABORTED' || error.message === 'BS_BINDING_ABORTED');
        return Promise.reject(error);
    }
);

export default {
    async login(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/login", payload);
    },
    async loginGoogle(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/login/google", payload);
    },
    async loginMicrosoft(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/login/microsoft", payload);
    },
    async loginLdap(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/login/ldap", payload);
    },
    async logout(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/logout", payload);
    },
    getAuthKey(uuid) {
        return authClient.get("/user/auth" + (!uuid ? "" : "/" + uuid));
    },
    getAuthStep(uuid) {
        return authClient.get("/user/auth/step" + (!uuid ? "" : "/" + uuid));
    },
    getAuthSkip() {
        return authClient.get("/user/auth/skip");
    },
    async sendVerification() {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/email/verification-notification");
    },
    async forgotPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/forgot-password", payload);
    },
    async resetPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/reset-password", payload);
    },
    async twoFactorEmailResend(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/user/auth/2fae/resend", payload);
    },
    async twoFactorEmailVerify(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/user/auth/2fae/verify", payload);
    },
    async register(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/register", payload);
    },
    async twoFactorChallenge(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/two-factor-challenge", payload);
    }
}
