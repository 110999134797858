import {generateString} from "./generateString.js";
import {encryption} from "./encryption.js"
import {dateHelper} from "./helpers/dateHelper.js";
import AuthService from '../services/auth.js';
import authClient from "../services/auth.js";
import store from "../stores/store.js";

export const geoKey = {
    expired : 7,
    secret : 'DmByqrLMESdLLvgoqP6WUlBCPJnlMJ0H',
    setTKey(keyString) {
        geoKey.setKeys({ t: keyString });
    },
    setKeys(objects) {
        const encrypted = new encryption();
        const lsGeoKeyValue = window.localStorage.getItem("gkv");
        let lsKey = window.localStorage.getItem("lsk");
        let result = null;
        let secret = null;
        if(lsKey && lsGeoKeyValue) {
            lsKey = encrypted.decrypt(lsKey, geoKey.secret);
            secret = JSON.parse(encrypted.decrypt(lsGeoKeyValue, lsKey));
            if(dateHelper.isDateEarlierThanNow(secret.e)) {
                window.localStorage.removeItem("gkv");
                store.dispatch("auth/setGuest", { value: "isGuest" });
            } else {
                result = {
                    i: (objects.i ? objects.i : secret.i),
                    k: (objects.k ? objects.k : secret.k),
                    t: (objects.t ? objects.t : secret.t),
                    e: dateHelper.dateTime.default.format(dateHelper.addDay(geoKey.expired))
                };
                window.localStorage.setItem("gkv", encrypted.encrypt(JSON.stringify(result), lsKey));
            }
        }
    },
    getTKey() {
        const keys = geoKey.getKeys();
        return keys ? keys.t : null;
    },
    getKKey() {
        const keys = geoKey.getKeys();
        if(keys.k || null) {
            return geoKey.trueKey(keys.k);
        } else {
            return null;
        }
    },
    getIKey() {
        const keys = geoKey.getKeys();
        return keys ? keys.i : null;
    },
    getKeys() {
        const encrypted = new encryption();
        const lsGeoKeyValue = window.localStorage.getItem("gkv");
        let lsKey = window.localStorage.getItem("lsk");
        let result = null;
        let secret = null;
        if(lsKey && lsGeoKeyValue) {
            lsKey = encrypted.decrypt(lsKey, geoKey.secret);
            secret = JSON.parse(encrypted.decrypt(lsGeoKeyValue, lsKey));
            if(dateHelper.isDateEarlierThanNow(secret.e)) {
                window.localStorage.removeItem("gkv");
                store.dispatch("auth/setGuest", { value: "isGuest" });
            } else {
                result = {
                    i: secret.i,
                    k: secret.k,
                    t: secret.t,
                    e: dateHelper.dateTime.default.format(dateHelper.addDay(geoKey.expired))
                };
                window.localStorage.setItem("gkv", encrypted.encrypt(JSON.stringify(result), lsKey));
            }
        }
        return result;
    },
    getSignInKey() {
        return new Promise(async (resolve, reject) => {
                const encrypted = new encryption();
                let secret = {i:'', k:''};
                let lsKey = window.localStorage.getItem("lsk");
                let lsGeoKeyValue = window.localStorage.getItem("gkv");
                if (!lsKey) {
                    lsKey = generateString(32);
                    window.localStorage.setItem("lsk", encrypted.encrypt(lsKey, geoKey.secret));
                } else {
                    lsKey = encrypted.decrypt(lsKey, geoKey.secret);
                }

                if (lsGeoKeyValue) {
                    secret = JSON.parse(encrypted.decrypt(lsGeoKeyValue, lsKey));
                    if(dateHelper.isDateEarlierThanNow(secret.e)) {
                        secret = {i:'', k:''};
                        store.dispatch("auth/setGuest", { value: "isGuest" });
                    }
                }

                try {
                    let result = await AuthService.getAuthKey(secret.i);
                    if(result.data.error || result.data.authenticate) {
                        if(result.data.error==='You already authenticated.' || result.data.authenticate) {
                            await authClient.logout((secret.i?{gid: secret.i}:null));
                            result = await AuthService.getAuthKey(secret.i);
                        }
                    }

                    window.localStorage.setItem("gkv", encrypted.encrypt(JSON.stringify({
                        i: result.data.i,
                        k: result.data.k,
                        e: result.data.e
                    }), lsKey));

                    resolve({
                        i: result.data.i,
                        k: geoKey.trueKey(result.data.k)
                    });

                } catch (error) {
                    reject(error);
                }
            }
        );
    },
    trueKey(key) {
        const reversed = key.split('').reverse().join('');
        const partLength = reversed.length / 4;
        const part1 = reversed.substring(0, partLength);
        const part2 = reversed.substring(partLength, 2 * partLength);
        const part3 = reversed.substring(2 * partLength, 3 * partLength);
        const part4 = reversed.substring(3 * partLength, 4 * partLength)
        const merged = part2 + part4 + part1 + part3;
        return merged.split('').reverse().join('');
    }
}
