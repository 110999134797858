/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 29/10/2024
 */
import {bsToast} from "../class/bsToast.js";

export const connectionHelper = {
    onLine() {
        if (!navigator.onLine) {
            connectionHelper.bsToast.show('No internet connection.');
        }
    }
}

connectionHelper.bsToast = new bsToast({
    class: 'border-0',
    classUniq: 'internet-connection',
    body :{
        class : 'text-white'
    }
});
