export const userHelper = {
    getAlias(name) {
        // Split the name into words
        const words = name.split(' ');

        if (words.length === 1) {
            // If there is only one word, use the first two characters as the alias
            return name.substring(0, 2).toUpperCase();
        } else if (words.length >= 2) {
            // If there are multiple words, take the first letter of each word
            const firstLetters = words.map(word => word.charAt(0));
            if(firstLetters.length>=3) {
                return firstLetters.join('').toUpperCase().substring(0, 2);
            }
            return firstLetters.join('').toUpperCase();
        } else {
            // Handle the case where the name is empty or undefined
            // You can return a default value or handle it as needed
            return 'DF'; // Or return another default value
        }
    }
}
