/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 28/10/2024
 */
import {utilHelper} from "../helpers/utilHelper.js";
import {arrayHelper} from "../helpers/arrayHelper.js";
import {objectHelper} from "../helpers/objectHelper.js";

export class bsToast {
    constructor(options = {}) {
        this.toastElement = null;
        this.toastInstance = null;
        this.toastOptions = options;
        this.toastId = 'toast-' + utilHelper.getUniqueId();
    }

    createToast() {
        const toastElement = document.createElement('div');
        toastElement.id = this.toastId;
        toastElement.setAttribute('role', 'alert');
        toastElement.setAttribute('aria-live', 'assertive');
        toastElement.setAttribute('aria-atomic', 'true');
        toastElement.classList.add('bs-toast', 'toast', 'fade', 'position-absolute');

        if(this.toastOptions.hasOwnProperty('position')) {
            if(this.toastOptions.position) {
                switch (this.toastOptions.position) {
                    case 'top-left':
                        toastElement.classList.add('top-0', 'start-0', 'ms-2', 'mt-2');
                        break;
                    case 'top-center':
                        toastElement.classList.add('top-0', 'start-50', 'mt-2', 'translate-middle-x');
                        break;
                    case 'top-right':
                        toastElement.classList.add('top-0', 'end-0', 'me-2', 'mt-2');
                        break;
                    case 'middle-left':
                        toastElement.classList.add('top-50', 'start-0', 'ms-2', 'translate-middle-y');
                        break;
                    case 'middle-center':
                        toastElement.classList.add('top-50', 'start-50', 'translate-middle');
                        break;
                    case 'middle-right':
                        toastElement.classList.add('top-50', 'end-0', 'me-2', 'translate-middle-y');
                        break;
                    case 'bottom-center':
                        toastElement.classList.add('bottom-0', 'start-50', 'mb-2', 'translate-middle-x');
                        break;
                    case 'bottom-right':
                        toastElement.classList.add('bottom-0', 'end-0', 'me-2', 'mb-2');
                        break;
                    default:
                        toastElement.classList.add('bottom-0', 'start-0', 'ms-2', 'mb-2');
                        break;
                }
                toastElement.classList.add('pos-' + this.toastOptions.position);
            } else {
                toastElement.classList.add('bottom-0', 'start-0', 'ms-2', 'mb-2', 'pos-bottom-left');
            }
        } else {
            toastElement.classList.add('bottom-0', 'start-0', 'ms-2', 'mb-2', 'pos-bottom-left');
        }

        if(this.toastOptions.hasOwnProperty('class')) {
            if(arrayHelper.isArray(this.toastOptions.class)) {
                if(this.toastOptions.class.length) {
                    this.toastOptions.class.forEach(stringClass => {
                        toastElement.classList.add(stringClass);
                    });
                }
            } else if (this.toastOptions.class) {
                toastElement.classList.add(this.toastOptions.class);
            }
        }

        if(this.toastOptions.hasOwnProperty('classUniq')) {
            if(this.toastOptions.classUniq) {
                toastElement.classList.add('uniq-toast-' + this.toastOptions.classUniq);
            }
        }

        return toastElement;
    }

    createHeader() {
        if(!this.toastOptions.hasOwnProperty('header')) {
            return null;
        }

        if(!objectHelper.isObject(this.toastOptions.header)) {
            return null;
        }

        if(objectHelper.isEmptyObject(this.toastOptions.header)) {
            return null;
        }

        if(!this.toastOptions.header.hasOwnProperty('title')) {
            return null;
        }

        if(!this.toastOptions.header.title) {
            return null;
        }

        const headerElement = document.createElement('div');
        const closeButton = document.createElement('button');
        const titleElement = document.createElement('div');
        const iconElement = document.createElement('i');

        iconElement.classList.add('ti', 'ti-xs', 'me-2');
        if(this.toastOptions.header.hasOwnProperty('iconClass')) {
            if (this.toastOptions.header.iconClass) {
                iconElement.classList.add('ti-' + this.toastOptions.header.iconClass);
                headerElement.appendChild(iconElement);
            }
        }

        if(this.toastOptions.header.hasOwnProperty('iconClassOther')) {
            if(arrayHelper.isArray(this.toastOptions.header.iconClassOther)) {
                if(this.toastOptions.header.iconClassOther.length) {
                    this.toastOptions.header.iconClassOther.forEach(stringClass => {
                        iconElement.classList.add(stringClass);
                    });
                }
            } else if (this.toastOptions.header.iconClassOther) {
                iconElement.classList.add(this.toastOptions.header.iconClassOther);
            }
        }

        titleElement.classList.add('me-auto', 'fw-medium');
        if(this.toastOptions.header.hasOwnProperty('class')) {
            if(arrayHelper.isArray(this.toastOptions.header.class)) {
                if(this.toastOptions.header.class.length) {
                    this.toastOptions.header.class.forEach(stringClass => {
                        titleElement.classList.add(stringClass);
                    });
                }
            } else if (this.toastOptions.header.class) {
                titleElement.classList.add(this.toastOptions.header.class);
            }
        }
        titleElement.innerText = this.toastOptions.header.title;
        headerElement.appendChild(titleElement);

        closeButton.classList.add('btn-close');
        closeButton.setAttribute('type', 'button');
        closeButton.setAttribute('data-bs-dismiss', 'toast');
        closeButton.setAttribute('aria-label', 'Close');

        if(this.toastOptions.header.hasOwnProperty('closeButtonClass')) {
            if(arrayHelper.isArray(this.toastOptions.header.closeButtonClass)) {
                if(this.toastOptions.header.closeButtonClass.length) {
                    this.toastOptions.header.closeButtonClass.forEach(stringClass => {
                        closeButton.classList.add(stringClass);
                    });
                }
            } else if (this.toastOptions.header.closeButtonClass) {
                closeButton.classList.add(this.toastOptions.header.closeButtonClass);
            }
        }

        headerElement.appendChild(closeButton);
        headerElement.classList.add('toast-header');
        return headerElement;
    }

    show(message, options = {}) {
        if(!this.isShownUniq()) {
            const bodyElement = document.createElement('div');
            const flexElement = document.createElement('div');
            const closeButton = document.createElement('button');
            const toastElement = this.createToast();
            const toastHeader = this.createHeader();
            let showOptions = { animation : true, autohide: true, delay: 5000 };

            flexElement.classList.add('d-flex');
            closeButton.classList.add('btn-close', 'me-2', 'm-auto');
            closeButton.setAttribute('type', 'button');
            closeButton.setAttribute('data-bs-dismiss', 'toast');
            closeButton.setAttribute('aria-label', 'Close');

            bodyElement.classList.add('toast-body');
            if(this.toastOptions.hasOwnProperty('body')) {
                if(objectHelper.isObject(this.toastOptions.body)) {
                    if(!objectHelper.isEmptyObject(this.toastOptions.body)) {
                        if(this.toastOptions.body.hasOwnProperty('class')) {
                            if(arrayHelper.isArray(this.toastOptions.body.class)) {
                                if(this.toastOptions.body.class.length) {
                                    this.toastOptions.body.class.forEach(stringClass => {
                                        bodyElement.classList.add(stringClass);
                                    });
                                }
                            } else if (this.toastOptions.body.class) {
                                bodyElement.classList.add(this.toastOptions.body.class);
                            }
                        }

                        if(this.toastOptions.body.hasOwnProperty('options')) {
                            if(objectHelper.isObject(this.toastOptions.body.options)) {
                                if(!objectHelper.isEmptyObject(this.toastOptions.body.options)) {
                                    showOptions = Object.assign(showOptions, this.toastOptions.body.options);
                                }
                            }
                        }

                        if(this.toastOptions.body.hasOwnProperty('closeButtonClass')) {
                            if(arrayHelper.isArray(this.toastOptions.body.closeButtonClass)) {
                                if(this.toastOptions.body.closeButtonClass.length) {
                                    this.toastOptions.body.closeButtonClass.forEach(stringClass => {
                                        closeButton.classList.add(stringClass);
                                    });
                                }
                            } else if (this.toastOptions.body.closeButtonClass) {
                                closeButton.classList.add(this.toastOptions.body.closeButtonClass);
                            } else {
                                closeButton.classList.add('btn-close-white');
                            }
                        } else {
                            closeButton.classList.add('btn-close-white');
                        }
                    }
                }
            }

            if(objectHelper.isObject(options)) {
                if(!objectHelper.isEmptyObject(options)) {
                    showOptions = Object.assign(showOptions, options);
                }
            }

            bodyElement.innerHTML = message;

            if(toastHeader) {
                toastElement.appendChild(toastHeader);
                toastElement.appendChild(bodyElement);
            } else {
                flexElement.appendChild(bodyElement);
                flexElement.appendChild(closeButton);
                toastElement.appendChild(flexElement);
            }


            if(this.hide()) {
                this.toastElement = toastElement;
                document.body.appendChild(this.toastElement);
                this.toastInstance = new bootstrap.Toast(this.toastElement, showOptions);
                this.toastElement.addEventListener('hidden.bs.toast', () => this.dispose());
                this.toastInstance.show();
            }
        }
    }

    hide() {
        if(!this.toastInstance) {
            return true;
        }

        if(this.toastInstance.isShown) {
            return false;
        }

        this.toastInstance.hide();
        this.dispose();
        return true;
    }

    dispose() {
        if(this.toastInstance) {
            this.toastElement.removeEventListener('hidden.bs.toast', () => this.dispose());
            this.toastInstance.dispose();
            this.toastElement.remove();
            this.toastElement=null;
            this.toastInstance=null;
        }
    }

    isShownUniq() {
        if(this.toastOptions.hasOwnProperty('classUniq')) {
            if(this.toastOptions.classUniq) {
                const element = window.document.querySelector('.uniq-toast-' + this.toastOptions.classUniq);
                if(element) {
                    return true;
                }
            }
        }
        return false;
    }
}
