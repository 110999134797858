/**
 * Created By : Andriyanto.
 * E-mail Address : 4lenour@gmail.com
 * Date: 25/02/2024
 * Time: 22:00
 */

import {apiClient} from "./apiClient.js";

export default {
    getShortcuts() {
        return apiClient.post('/user/shortcuts');
    },
    getMenus(category) {
        return apiClient.post('/user/menu/' + category);
    }
}
