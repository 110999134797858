export const utilHelper = {
    getUniqueId() {
        // Generate a random 16-character string
        const randomString = Math.random().toString(36).substring(2, 18);
        // Add a timestamp to the beginning of the string
        const timestamp = Date.now();
        // Return the unique ID string
        return timestamp + randomString;
    },
    generatePassword(length = 8) {
        const getRandomCharacter =  (characterSet) => {
            const randomIndex = Math.floor(Math.random() * characterSet.length);
            return characterSet.charAt(randomIndex);
        };

        const getShuffleArray = (array) => {
            const shuffledArray = [...array]; // Create a new array to avoid modifying the original array
            for (let i = shuffledArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                // Swap elements without destructuring assignment
                const temp = shuffledArray[i];
                shuffledArray[i] = shuffledArray[j];
                shuffledArray[j] = temp;
            }
            return shuffledArray;
        }

        if (length < 8) {
            throw new Error("Password length must be at least 8 characters");
        }

        const uppercaseLetter = getRandomCharacter("ABCDEFGHIJKLMNOPQRSTUVWXYZ");
        const digit = getRandomCharacter("0123456789");
        const specialChar = getRandomCharacter("!@#$%&*?");
        const requiredChars = [uppercaseLetter, digit, specialChar];
        const remainingLength = length - requiredChars.length;
        const allCharacters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*?";
        const passwordTail = Array.from({ length: remainingLength }, () => getRandomCharacter(allCharacters)).join('');

        // Combine all parts and shuffle to ensure randomness
        return getShuffleArray(requiredChars.concat(passwordTail.split(''))).join('');
    },
    setAlphaNumeric(value) {
        return value.replace(/[^a-z0-9]/gi, '');
    },
    setPhoneNumber(value) {
        // const allowedChars = '0123456789()+- ,';
        return value.replace(/[^0-9()+\-, ]/g, '');
    },
    setNormalizeSlug(value) {
        return value.replace(/[^a-zA-Z0-9\-]/g, '').toLowerCase();
    },
    setNormalizeDomain(value) {
        return value.replace(/[^a-zA-Z0-9\-.]/g, '').toLowerCase();
    }
}
