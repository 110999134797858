/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 24/10/2024
 */
import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";


export const HrAppDefaults = [
    {
        path: appHelper.path.hrApp,
        name:'hrApp',
        component: () => import('../views/dashboard/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    }
]
