import {geoKey} from "../utils/geoKey.js";
export default function verify({to, next, store}) {
    const storageItem = window.localStorage.getItem("guest");
    if (storageItem === "isNotGuest" && !store.getters["auth/authUser"]) {
        store.dispatch("auth/getAuthUser").then(() => {
            if (store.getters["auth/authUser"]) {
                next({ name: 'home' });
            } else {
                store.dispatch("auth/setGuest", { value: "isGuest" });
                next();
            }
        });
    } else if(to.params.gid) {
        if(geoKey.getIKey()===to.params.gid) {
            store.dispatch("auth/getAuthStep", to.params.gid).then(result => {
                if (result.token) {
                    geoKey.setTKey(result.token);
                    store.dispatch("auth/getAuthUser").then(() => {
                        store.dispatch("auth/setGuest", { value: "isNotGuest" });
                        next({name: 'home'});
                    }).catch(err => {
                        next();
                    });
                } else if (result.to !== to.name) {
                    next({name: result.to, query: to.query});
                } else {
                    next();
                }
            });
        } else {
            store.dispatch("auth/getAuthSkip").then(result => {
                next();
            }).catch(error => {
                next();
            });
        }
    } else {
        store.dispatch("auth/getAuthStep").then(result => {
            if (result.to !== to.name) {
                next({name: result.to, query: to.query});
            } else {
                next();
            }
        });
    }

}
