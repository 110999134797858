import { createRouter, createWebHistory } from 'vue-router';
import { AppRouters } from "./collections.js";
import store from "../stores/store.js";
import pipeline from "../middleware/pipeline.js";
import {layoutHelper} from "../utils/helpers/layoutHelper.js";
import {menuHelper} from "../utils/helpers/menuHelper.js";

const router = createRouter({
    history: createWebHistory(),
    routes: AppRouters
});

router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware;
    const context = { to, from, next, store }
    const direct = layoutHelper.findDirectRoute(to.name);

    if( direct ) {
        return next({ name: direct });
    } else if(!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: pipeline(context, middleware, 1)
    });
});

router.afterEach(async (to, from, failure) => {
    if (!failure) {
        menuHelper.handleMenuToggleCollapsed();
        await menuHelper.initialized();
    }
});

export default router
