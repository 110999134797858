/**
 * Created By: Andriyanto.
 * E-mail Address: 4lenour@gmail.com
 * Date: 25/02/2024
 * Time: 21:48
 */

import MenuService from '../../services/menu.js';
import {errorHelper} from "../../utils/helpers/errorHelper.js";
import {menuHelper} from "../../utils/helpers/menuHelper.js";
import {arrayHelper} from "../../utils/helpers/arrayHelper.js";

export const menu = {
    namespaced: true,
    state: () => ({
        shortcuts: [],
        shortcutRequest: false,
        current: null,
        parent: null,
        page: null,
        menus: [],
        permissions: []
    }),
    getters : {
        shortcutRequest: (state) => {
            return state.shortcutRequest;
        },
        shortcuts: (state) => {
            return state.shortcuts;
        },
        current: (state) => {
            return state.current;
        },
        menus: (state) => {
            return state.menus;
        },
        parent: (state) => {
            return state.parent;
        },
        page: (state) => {
            return state.page;
        },
        permissions: (state) => {
            return state.permissions;
        },
        tabs: (state) => {
            const items=[];
            if(state.parent) {
                if(state.parent.menu_type==='tab') {
                    if(state.parent.hasOwnProperty('menus')) {
                        if (state.parent.menus.length) {
                            for (const obj of state.parent.menus) {
                                items.push({
                                    id: obj.id,
                                    label: obj.name,
                                    iconClass: (obj.icon_class?(obj.icon_type==='tabler'?'ti ti-':'bi bi-') + obj.icon_class:null),
                                    routePath: obj.route_frontend,
                                    routeName: obj.route_middleware,
                                    routeType: obj.route_type
                                })
                            }
                        }
                    }
                }
            }
            return items;
        },
        title: (state) => {
            if(state.page) {
                return state.page.title;
            }
            return null;
        },
        iconClass: (state) => {
            if(state.page) {
                return !state.page.icon_class?null:((state.page.icon_type==='tabler'?'ti ti-':'bi bi-') + state.page.icon_class);
            }
            return null;
        },
        parentTitle: (state) => {
            if(state.parent) {
                return state.parent.title;
            }
            return null;
        },
        parentIconClass: (state) => {
            if(state.parent) {
                return !state.parent.icon_class?null:((state.parent.icon_type==='tabler'?'ti ti-':'bi bi-') + state.parent.icon_class);
            }
            return null;
        },
        canRead:(state) => {
            if(state.permissions.length) {
                return arrayHelper.inArray(state.permissions, 'read');
            }
            return false;
        },
        canCreate:(state) => {
            if(state.permissions.length) {
                return arrayHelper.inArray(state.permissions, 'created');
            }
            return false;
        },
        canEdit:(state) => {
            if(state.permissions.length) {
                return arrayHelper.inArray(state.permissions, 'updated');
            }
            return false;
        },
        canDelete:(state) => {
            if(state.permissions.length) {
                return arrayHelper.inArray(state.permissions, 'deleted');
            }
            return false;
        },
        canTrash:(state) => {
            if(state.permissions.length) {
                return arrayHelper.inArray(state.permissions, 'trashed');
            }
            return false;
        },
        canResetPassword:(state) => {
            if(state.permissions.length) {
                return arrayHelper.inArray(state.permissions, 'reset-password');
            }
            return false;
        },
        canLoginAs:(state) => {
            if(state.permissions.length) {
                return arrayHelper.inArray(state.permissions, 'login-as');
            }
            return false;
        },
        hasFeature:(state) => (featureKey) =>  {
            if((state.page || {}).hasOwnProperty('features')) {
                if(featureKey) {
                    if(state.page.features.length) {
                        return arrayHelper.inArray(state.page.features, featureKey.toLowerCase());
                    }
                }
            }
            return false;
        },
    },
    mutations : {
        setShortcutRequest(state, shortcutRequest) {
            state.shortcutRequest = shortcutRequest;
        },
        setShortcuts(state, shortcuts) {
            state.shortcuts = shortcuts;
        },
        setCurrent(state, current) {
            state.current = current;
        },
        setMenus(state, menus) {
            state.menus = menus;
        },
        setParent(state, parent) {
            state.parent = parent;
        },
        setPage(state, page) {
            state.page = page;
        },
        setPermissions(state, permissions) {
            state.permissions = permissions;
        },
        markUpdate(state) {
            if(state.current) {
                if(!state.current.hasOwnProperty('hasInit')) {
                    const index = state.shortcuts.findIndex(item => item.id === state.current.id);
                    let newMenus=[];
                    if(state.current.menu_type.toLowerCase()==='vertical') {
                        newMenus = menuHelper.normalizeVerticalMenus(state.menus);
                    } else if(state.current.menu_type.toLowerCase()==='horizontal') {
                        newMenus = menuHelper.indexingMenus(state.menus);
                    }
                    state.shortcuts[index] = {...state.current, menus:newMenus, hasInit:true};
                    state.current = state.shortcuts[index];
                    state.menus = menuHelper.markRoute(newMenus);
                } else {
                    state.menus = menuHelper.markRoute(menuHelper.indexingMenus(state.menus));
                }
            }
        }
    },
    actions : {
        async getShortcuts({ commit }) {
            commit('setShortcutRequest', true);
            try {
                const response = await MenuService.getShortcuts();
                commit('setShortcuts', response.data);
                commit('setShortcutRequest', false);
                return response.data;
            } catch (error) {
                errorHelper.error(error);
                commit('setShortcutRequest', false);
            }
        },
        async getMenus({ commit }, category) {
            try {
                const response = await MenuService.getMenus(category);
                return response.data;
            } catch (error) {
                errorHelper.error(error);
                return Promise.reject(error);
            }
        }
    }
}
