<template>
    <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme border-end">
        <div class="app-brand demo">
            <router-link :to="$gpApp.go.pathApp" class="app-brand-link">
                <span class="app-brand-logo">
                    <img :src="$gpApp.logo()" alt="Logo">
                </span>
                <span class="app-brand-text demo menu-text fw-bold">{{ $gpApp.shortname() }}</span>
            </router-link>

            <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto">
                <i class="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
                <i class="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
            </a>
        </div>

        <div class="menu-inner-shadow"></div>
        <MainMenuItem class="menu-inner py-1" :items="$store.getters['menu/menus']" />
    </aside>
</template>

<script>
import MainMenuItem from "./MainMenuItem.vue";
import {Menu} from "../../utils/vuexy/menu.js";
export default {
    name: "NavMainMenu",
    components : {
        MainMenuItem
    },
    mounted() {
        const enable = true;
        this.initializeMenu();
        window.Helpers.setAutoUpdate(enable);
        this.$parent.$refs.navbar.layoutMenuToggle();
        this.layoutMenuToggle();
    },
    beforeUnmount() {
        window.Helpers._unbindMenuMouseEvents();
        window.Helpers.mainMenu.destroy();
    },
    methods : {
        layoutMenuToggle() {
            const layoutMenuToggle = this.$el.querySelector('.layout-menu-toggle');
            if(layoutMenuToggle) {
                if(!layoutMenuToggle.classList.contains('on-event-click')) {
                    layoutMenuToggle.classList.add('on-event-click');
                    layoutMenuToggle.addEventListener('click', event => {
                        window.Helpers.toggleCollapsed();
                    });
                }
            }
        },
        initializeMenu() {
            const menu = new Menu(this.$el, {
                orientation: 'vertical',
                closeChildren: false,
                showDropdownOnHover: true
            });

            // Change parameter to true if you want scroll animation
            window.Helpers.scrollToActive(true);
            window.Helpers.mainMenu = menu;
        }
    }
}
</script>

<style scoped>

</style>
