/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 23/05/2024
 */
import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

export const TmsDefaults = [
    {
        name: 'tms-home',
        path: appHelper.path.home + '/tms',
        component: () => import('../views/home/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    }
];
