export const objectHelper = {
    isObject(variable) {
        return typeof variable === 'object' && variable !== null && !Array.isArray(variable);
    },
    isEmptyObject(obj) {
        if(objectHelper.isObject(obj)) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        }
        return true;
    },
    isJsonString(str) {
        // Check if the string is a valid JSON string.
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        // The string is a valid JSON string.
        return true;
    },
    isJsonStringAndTypeIsArray(jsonString) {
        // Check if the string is a JSON string.
        if (!objectHelper.isJsonString(jsonString)) {
            return false;
        }

        // Convert the JSON string to a JavaScript object.
        const jsonObject = JSON.parse(jsonString);

        // Check if the type of the JSON object is an array.
        return Array.isArray(jsonObject);
    },
    findNestedById(arr, targetId) {
        for (const obj of arr) {
            if (obj.id === targetId) {
                return obj;
            }

            if(obj.hasOwnProperty('children')) {
                if (obj.children.length > 0) {
                    const result = objectHelper.findNestedById(obj.children, targetId);
                    if (result) {
                        return result;
                    }
                }
            }
        }

        // If the object with the specified id is not found
        return null;
    },
    removeNestedById(arr, targetId, childrenEmptyRemovedParent = false) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === targetId) {
                arr.splice(i, 1);
                return true; // Object found and removed
            }

            if(arr[i].hasOwnProperty('children')) {
                if (arr[i].children.length > 0) {
                    const result = objectHelper.removeNestedById(arr[i].children, targetId);
                    if (result) {
                        // If an object is removed from children, check if children array is empty, remove the parent
                        if(childrenEmptyRemovedParent) {
                            if (arr[i].children.length === 0) {
                                arr.splice(i, 1);
                            }
                        }
                        return true;
                    }
                }
            }
        }
        return false; // If the object with the specified id is not found
    },
    getClone(object) {
        if(objectHelper.isObject(object)) {
            return JSON.parse(JSON.stringify(object));
        } else {
            return object;
        }
    }
}
