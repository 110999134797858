import { createStore } from "vuex/dist/vuex.esm-browser.js";
import { auth } from "./modules/auth";
import { menu } from "./modules/menu";

const store = createStore({
    strict: true,
    modules : {
        auth,
        menu
    }
});

export default store
